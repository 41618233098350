import { Link } from '@inertiajs/react'

export default function GameCard({game}) {
    const { id, name, cover_url } = game;
    return (
        <div className="text-center">
            <div className={`card shadow-xl overflow-hidden relative group`}>
                <div className='absolute w-full h-full bg-neutral/50 gap-2 p-4 justify-center hidden group-hover:flex flex-col'>
                    <Link href={route('championships')} data={{ game: name }} className="btn btn-primary">View Championships</Link>
                    <Link href={route('events')} data={{ game: name }} className="btn btn-primary">View Events</Link>
                </div>
                <img src={cover_url} alt={name} />
            </div>
            <h3 className="mt-3">{name}</h3>
        </div>
    );
}
